import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import classnames from 'classnames';
import React, { useState } from 'react';
import ContactForm from '../../../components/Common/ContactForm';
import Layout from '../../../components/Common/Layout';
import Share from '../../../components/Common/Share';
import Sidebar from '../../../components/Common/Sidebar';
import { DataToFetch } from './DataFetched';
import styles from './style';
import IconClose from '@material-ui/icons/Close';
import SnackBar from '@material-ui/core/Snackbar';
interface IBlogDetails {
  data: any;
}
const BlogDetails_contenu = (props: IBlogDetails) => {
  const { data } = props;
  const [openSnack, setOpenSnack] = useState(false);

  //const temp = DataToFetch(data[0].node.slug, setOpenSnack);

  const dataFetched = data[0].node;

  const content = dataFetched.content;

  const handleCloseSnack = () => {
    setOpenSnack(!openSnack);
  };

  const classes = styles();
  const media = dataFetched.featuredImage.node.sourceUrl;
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  return (
    <Box className={classes.actualite}>
      <Layout className={classes.container}>
        <Box className={classes.main}>
          <Card className={classes.card}>
            <CardMedia className={classes.media} image={media} title="title">
              <img src={media} alt="title" />
            </CardMedia>
            <Box className={classes.header}>
              <Box display="flex" alignItems="center" className={classes.meta}>
                <span>Publié</span>
                <CalendarTodayIcon />
                {dataFetched.date}
              </Box>
              <Typography component="h1" className={classnames(classes.title)}>
                {dataFetched.title}
              </Typography>
            </Box>
            {/* share is static or not?? */}
            <Share htmlColor="#C4C4C4" />
            <CardContent
              className={classnames('textParagraphe', classes.content)}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: content ? content : '' }}
              />
              
              {/*content[0]*/}
            </CardContent>
          </Card>
        </Box>
        <Sidebar />
      </Layout>
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
      <SnackBar
        open={openSnack}
        message={'chargement ...'}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleCloseSnack}
        action={
          <IconButton size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Box>
  );
};
export default BlogDetails_contenu;
