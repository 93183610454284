import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Container from '../../components/Container';
import BlogDetails_contenu from './BlogDetails-contenu';

const BlogDetail = (data: any) => {
  const ID = data.pageContext.data.id;

  const dataFetched: {
    allWpPost: {
      edges: [
        {
          node: {
            id: string;
            title: string;
            content: string;
            date: string;
            featuredImage: {
              node: {
                sourceUrl: string;
              };
            };
          };
        },
      ];
    };
  } = useStaticQuery(graphql`
    query {
      allWpPost {
        edges {
          node {
            id
            slug
            title
            content
            date(formatString: "DD MMMM yyyy", locale: "fr-FR")
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);
  const blogDetails = dataFetched.allWpPost.edges.filter(
    (item) => item.node.id === ID,
  );

  return (
    <Container>
      <BlogDetails_contenu data={blogDetails} />
    </Container>
  );
};
export default BlogDetail;
